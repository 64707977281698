import req from "../methods/req"
import method from '../methods/method'

// 订单列表
export const orderList = function({
    _this,
    data = {}
}) {
    return method.get_list({
        _this,
        url: '/manage/crm.order/report',
        data
    })
}


export const orderInfo = function({
    data = {}
}) {
    return req({
        url: '/manage/crm.order/info',
        data
    })
}

export const editOrder = function({
    data = {}
}) {
    return req({
        url: '/manage/crm.order_v2/editOrder',
        data
    })
}


// 库存上报列表
export const stockList = function({
    _this,
    data = {}
}) {
    return method.get_list({
        _this,
        url: '/manage/crm.stock/report',
        data
    })
}

// 库存上报列表商品列表
export const stockListGoodsInfo = function({
    data = {}
}) {
    return req({
        url: '/manage/crm.stock/goodsInfo',
        data
    })
}


// 补单预警列表
export const stockOrderWarn = function({
    _this,
    data = {}
}) {
    return method.get_list({
        _this,
        url: '/manage/crm.stock/orderWarn',
        data
    })
}


// 补单预警商品
export const stockWarnGoods = function({
    data = {}
}) {
    return req({
        url: '/manage/crm.stock/warnGoods',
        data
    })
}

// 订单报表
export const orderReport = function(data) {
    return req({
        url: '/manage/crm.order/orderReport',
        ...data
    })
}

// 订单报表
export const orderReportSub = function(data) {
    return req({
        url: '/manage/crm.order/orderReportSub',
        ...data
    })
}


// 竞品上报列表
export const competeReport = function({
    _this,
    data = {}
}) {
    return method.get_list({
        _this,
        url: '/manage/crm.stock/competeReport',
        data
    })
}

// 竞品上报列表商品列表
export const competeInfo = function({
    data = {}
}) {
    return req({
        url: '/manage/crm.stock/competeInfo',
        data
    })
}

// 订单设置 
export const setOrderSetting = function(data) {
    return req({
        url: '/manage/crm.order_v2/setOrderSetting',
        ...data
    })
}
// 获取订单设置
export const getOrderSetting = function(data) {
    return req({
        url: '/manage/crm.order_v2/getOrderSetting',
        ...data
    })
}


// 根据订单获取列表 

export const orderCensus = function({
    _this,
    data = {}
}) {
    return method.get_list({
        _this,
        url: '/manage/crm.order_v2/orderCensus',
        data
    })
}

export const distributorCensus = function({
    _this,
    data = {}
}) {
    return method.get_list({
        _this,
        url: '/manage/crm.order_v2/distributorCensus',
        data
    })
}

export const goodsCensus = function({
    _this,
    data = {}
}) {
    return method.get_list({
        _this,
        url: '/manage/crm.order_v2/goodsCensus',
        data
    })
}
// 

export const getCheckout = function(data) {
    return req({
        url: '/manage/crm.order_v2/getCheckout',
        ...data
    })
}

// 
export const censusSub = function(data) {
    return req({
        url: '/manage/crm.order_v2/censusSub',
        ...data
    })
}

// 订单详情
export const orderInfo_new = function(data) {
    return req({
        url: '/manage/crm.order_v2/orderInfo',
        ...data
    })
}

// 取消订单 

export const cancelOrder = function(data) {
    return req({
        url: '/manage/crm.order_v2/cancelOrder',
        ...data
    })
}
// cancelCheckout,cancelOrder 
export const cancelCheckout = function(data) {
    return req({
        url: '/manage/crm.order_v2/cancelCheckout',
        ...data
    })
}

