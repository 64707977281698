export default {
	rowKey:{
		type:String,
		default:'key'
	},
	columns: {
		type: Array,
		default: () => {
			return [];
		}
	},
}